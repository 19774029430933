/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-01-15 18:03:32
 */

import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Routes } from './Router/Routes'
import './index.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const BrowseRouter = createBrowserRouter(Routes)

root.render(
	<React.StrictMode>
		<RouterProvider router={BrowseRouter} />
	</React.StrictMode>
)
