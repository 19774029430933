import { Card, Col, Container, Row } from "react-bootstrap";
import { FaClock } from "react-icons/fa";
import { GiCookingGlove } from "react-icons/gi";
import { IoRestaurantSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Rodape } from "../Footer/Rodape";
import BaraoDois from "../Images/Receitas/Esmaecido/BaraoDeDoisE.jpeg";
import BolinhoFeijoada from "../Images/Receitas/Esmaecido/BolinhoDeFeijoadaE.jpeg";
import BolinhoFeijao from "../Images/Receitas/Esmaecido/BolinhoDeFeijãoE.jpeg";
import recipe from "../Images/Receitas/header-receitas.png";
import { TopBar } from "../Navbar/TopBar";


export const ReceitasHome = () => {
	return (
		<>
            <TopBar />
            <HEADER />
            <RecipeLinks />
            <Rodape />
		</>
	)
}


const HEADER = () => {
    return (
        <>
            <Card className="bg-primary mb-4">
                <Card.Img src={recipe}></Card.Img>
				<Card.ImgOverlay>
					<Card.Title className="text-white text-center">
                        <div className="a">
						    <h3 className="border-5 display-1 mt-lg-5">
                                Receitas da Barão
                            </h3>
                        </div>
					</Card.Title>
				</Card.ImgOverlay>
			</Card>
        </>
    )
}

const RecipeLinks = () => {
    return (
        <>
            <Container>
                <Row xl={10}>
                    <Col md={4}>
                    <Link to="/BaraoDois">
                        <Card className="mb-3" style={{ maxWidth: '24rem' }}>
                            <Card.Img style={{opacity: 60}} variant="top" src={BaraoDois} />
                            <Card.ImgOverlay>
                                <Card.Title className="text-white">Barão de Dois</Card.Title>
                                <Card.Subtitle className="text-white">
                                    <span><FaClock /> +1H </span>
                                    <span><IoRestaurantSharp /> 8 Porções</span>
                                    <span><GiCookingGlove /> Fácil </span>
                                </Card.Subtitle>
                            </Card.ImgOverlay>
                        </Card>
                    </Link>
                    </Col>
                    <Col md={4}>
                    <Link to="/BolinhoFeijao">
                        <Card className="mb-3" style={{ maxWidth: '24rem' }}>
                            <Card.Img variant="top" src={BolinhoFeijao} />
                            <Card.ImgOverlay>
                                <Card.Title className="text-white">Bolinho de Feijão</Card.Title>
                                <Card.Subtitle className="text-white">
                                    <span><FaClock /> +1H </span>
                                    <span><IoRestaurantSharp /> 10 Porções </span>
                                    <span><GiCookingGlove /> Médio </span>
                                </Card.Subtitle>
                            </Card.ImgOverlay>
                        </Card>
                    </Link>
                    </Col>
                    <Col md={4}>
                    <Link to="/BolinhoFeijoada">
                        <Card style={{ maxWidth: '24rem' }}>
                            <Card.Img variant="top" src={BolinhoFeijoada} />
                            <Card.ImgOverlay>
                                <Card.Title className="text-white">Bolinho de Feijoada</Card.Title>
                                <Card.Subtitle className="text-white">
                                    <span><FaClock /> +1H </span>
                                    <span><IoRestaurantSharp /> 8 Porções</span>
                                    <span><GiCookingGlove /> Fácil </span>
                                </Card.Subtitle>
                            </Card.ImgOverlay>
                        </Card>
                    </Link>
                    </Col>
                </Row>
            </Container>
        </>
    )
}