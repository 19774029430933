/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-02-20 17:56:36
 */

import { Button, Col, Container, Image, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import LinhaVinagre from "../Images/LinhaProdutos/LinhaVinagre.png"
import VinAlcool from "../Images/LinhaProdutos/Vinagre/VinagreAlcool.png"
import VinAlcoolColorido from "../Images/LinhaProdutos/Vinagre/VinagreAlcoolColorido.png"
import VinAlcoolComposto from "../Images/LinhaProdutos/Vinagre/VinagreAlcoolComposto.png"
import VinMaçã from "../Images/LinhaProdutos/Vinagre/VinagreMaca.png"
import VinVinhoTinto from "../Images/LinhaProdutos/Vinagre/VinagreVinhoTinto.png"
import { TopBar } from "../Navbar/TopBar"

export const Vinagre = (): JSX.Element => {
    return(
        <>
        <TopBar/>
        <VIN/>
        <CARD/>
        </>
    )
}

const VIN = () =>(
    <>
    <Row className='bg-secondary'>
        <Col sm={5}>
            <Image className='mt-3' fluid width={450} src={LinhaVinagre}></Image>
        </Col>
        <Col sm={6} className='text-white text-center mt-4'>
            <h1>Linha Vinagre</h1>
            <p>
            Além de saborosa a farinha Barão Premium é saudável e nutritiva,
            com uma produção que evita o excesso do refinamento, mantendo
            assim seus nutrientes naturais.
            </p>
        </Col>
    </Row>
    </>
)

const CARD = () =>(
    <>
    <Container className='mt-5'>
      <Row>
        <Col className="text-center mb-5" xl={6}>
          <Image fluid width={270} src={VinAlcool}></Image>
          <h4 className="text-secondary">Vinagre Álcool</h4>
          <Link to="/VinAlcool">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={67} src={VinAlcoolColorido}></Image>
          <h4 className='text-secondary mt-3'>Vinagre Álcool Colorido</h4>
          <Link to="/VINCOLORIDO">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={70} src={VinAlcoolComposto}></Image>
          <h4 className='text-secondary'>Vinagre Álcool Composto</h4>
          <Link to="/VinComposto">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={250} src={VinMaçã}></Image>
          <h4 className='text-secondary'>Vinagre Maçã</h4>
          <Link to="/VinMaca">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={250} src={VinVinhoTinto}></Image>
          <h4 className='text-secondary'>Vinagre Vinho Tinto</h4>
          <Link to="/VinTinto">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
      </Row>
    </Container>
    </>
)