/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-02-20 13:55:35
 */



import { Button, Col, Container, Image, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import gp1 from "../../Images/LinhaProdutos/Feijao/FeijaoGarotinhoP.png";
import { TopBar } from "../../Navbar/TopBar";

export function FeijaoGarotinhoPreto() {
    return (
    <>
    <TopBar/>
    <Container>
    <TABS/>
    </Container>

    </>
    );
  }

  const TABS =() =>(
    <>
        <Row>
        <Col xl={3}>
            <Image className="mt-5 ms-3 text-center" fluid width={210} src={gp1}></Image>
        </Col>
        <Col xl={9}>
            <Tabs variant="pills"
                fill
                defaultActiveKey="info"
                id="uncontrolled-tab-example"
                className="mt-5">
            <Tab eventKey="info" title="Informações">
                <h1 className="text-center mt-3">Feijão Garotinho</h1>
                <p className="text-center">
                Assim como o arroz, o Feijão Garotinho Preto é um produto selecionado diretamente
                na lavoura, com grãos saudáveis e com baixo nível de toxinas, fornecendo
                qualidade e saúde em um produto essencial para a mesa do consumidor.
                </p>
            <Table className="text-center mt-4">
                <thead>
                    <tr>
                        <th> Classe </th>
                        <th> Tipo </th>
                        <th> Grupo </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Preto</td>
                        <td>1</td>
                        <td>1 - Feijão Comum</td>
                    </tr>
                </tbody>
            </Table>
            <Link to="/Products/LinhaFeijao">
                <Button
                    className="bi-arrow-return-left" variant="outline-danger"> Voltar
                </Button>
            </Link>
            </Tab>
            <Tab eventKey="tabela" title="Tabela Nutricional">
            <Table striped>
                <thead>
                    <tr>
                        <th>Porção de 50g / (medida caseira)</th>
                        <th>Quantidade por porção</th>
                        <th>%VD*</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Valor Calorico</td>
                        <td>133 kca</td>
                        <td>7%</td>
                    </tr>
                    <tr>
                        <td>Carboidrato</td>
                        <td>20g</td>
                        <td>7%</td>
                    </tr>
                    <tr>
                        <td>Proteina</td>
                        <td>11g</td>
                        <td>15%</td>
                    </tr>
                    <tr>
                        <td>Gorduras Totais</td>
                        <td>1,2g</td>
                        <td>2%</td>
                    </tr>
                    <tr>
                        <td>Gordura Saturada</td>
                        <td>0,3g</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>Colesterol</td>
                        <td>0 kca</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Fibra Alimentar</td>
                        <td>18g</td>
                        <td>72%</td>
                    </tr>
                    <tr>
                        <td>Cálcio</td>
                        <td>0,3g</td>
                        <td>0,3%</td>
                    </tr>
                    <tr>
                        <td>Ferro</td>
                        <td>6g</td>
                        <td>43%</td>
                    </tr>
                    <tr>
                        <td>Sódio</td>
                        <td>0g</td>
                        <td>0%</td>
                    </tr>
                </tbody>
            </Table>
            <Link to="/Products/LinhaFeijao">
                <Button
                    className="bi-arrow-return-left" variant="outline-danger"> Voltar
                </Button>
            </Link>
            </Tab>
            </Tabs>
        </Col>
        </Row>
    </>
  )