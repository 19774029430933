/**
 * @author Frederico Ferracini Duarte
 * @since 2024-01-16 12:39:03
 */

import { Container, Image, Nav, NavDropdown, Navbar } from "react-bootstrap"
import Barao from "../Images/Logo.png"

export const TopBar = () => (
	<Navbar sticky="top" collapseOnSelect expand="lg" bg="primary" data-bs-theme="dark">
		<Container >
			<Brand />
			<Navbar.Toggle />
			<Navbar.Collapse>
				<Nav>
					<Nav.Link
						href="/quemsomos">
						Quem Somos
					</Nav.Link>
					<Skus />
					<Nav.Link
						href="/RECIPES">
						Receitas
					</Nav.Link>
					<Nav.Link
						href="/faleconosco">
						Fale conosco
					</Nav.Link>
					<Nav.Link
						href="/TrabalheConosco">
						Trabalhe conosco
					</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Container>
	</Navbar>
)

const Brand = () => (
	<Navbar.Brand href="/">
		<Image src={Barao} style={{height: "1.9em"}} />
		&nbsp;
		Arroz e Feijão Barão
	</Navbar.Brand>
)

const Skus = () => (
	<NavDropdown title="Produtos">
		<NavDropdown.Item href="/Products/LinhaArroz">Linha Arroz</NavDropdown.Item>
		<NavDropdown.Item href="/Products/LinhaFeijao">Linha Feijão</NavDropdown.Item>
		<NavDropdown.Item href="/Products/LinhaFarinha">Linha Farinha</NavDropdown.Item>
		<NavDropdown.Item href="/Products/LinhaVinagre">Linha Vinagre</NavDropdown.Item>
	</NavDropdown>
)
