/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-01-23 10:59:40
 */

import { Col, Container, Image, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import siga from "../Images/SocialMedia/Siga.jpg"

export const Rede = () => {
	return (
        <>
            <Image className="mt-5 " src={siga} fluid ></Image>

            <div className="imagem">
            <h1
            className="text-center text-primary mt-4">Nossas Redes Sociais
            </h1>

            <Container className="mt-4 center text-center">
                <Row>

                <Col>
                    <Link to={"https://www.instagram.com/baraoalimentosoficial/"}>
                        <i
                        style={{fontSize: 55, color: '#C13584' ,}}
                        className="bi bi-instagram">
                        </i>
                    </Link>
                </Col>

                <Col>
                    <Link to={"https://www.facebook.com/baraoalimentosoficial"}>
                        <i
                        style={{fontSize: 55,}}
                        className="bi bi-facebook">
                        </i>
                    </Link>
                </Col>

                {/* <Col xs={3} className="mt-4 ">
                        <Link to="https://www.instagram.com/baraoalimentosoficial/">
                            <Image rounded fluid width={80} src={Insta}></Image>
                        </Link>
                    </Col>
                    <Col xs={3}>
                        <Link to="https://www.facebook.com/baraoalimentosoficial">
                            <Image rounded fluid width={130} src={Face}></Image>
                        </Link>
                    </Col> */}
                </Row>
            </Container>
            </div>
        </>
    )
}