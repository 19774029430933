/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-01-19 10:50:41
 */

import { Button, Container, Image } from "react-bootstrap"
import { Link } from "react-router-dom"
import produtos from "../Images/Prod.png"

export const Produtos = () => {
	return (
		<>
		<Container className="text-center">
		 	<Image className="container-md mt-5" src={produtos}/>
			<h1 className="mt-2 animated text-center">Conheça nossos Produtos</h1>
			<Link to="/Products/LinhaArroz">
				<Button variant="outline-primary"> Produtos </Button>
			</Link>
		</Container>
		</>
	)
}