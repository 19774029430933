/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-01-30 10:56:51
 */

import { Button, Col, Container, Image, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Rodape } from "../Footer/Rodape"
import FarinhaB from "../Images/LinhaProdutos/Farinha/FarinhaBarao.png"
import LinhaFarinha from "../Images/LinhaProdutos/LinhaFarinha.png"
import { TopBar } from "../Navbar/TopBar"

export const Farinha = (): JSX.Element => {
    return(
        <>
        <TopBar/>
        <QA/>
        <FARINHA/>
        <Rodape/>
        </>
    )
}

const QA = () =>(
    <>
    <Row className='bg-secondary'>
        <Col xl={5}>
            <Image className='mt-3' width={450} src={LinhaFarinha}></Image>
        </Col>
        <Col sm={5} className='text-white text-center mt-4'>
            <h1>Linha Farinha</h1>
            <p>
            Além de saborosa a farinha Barão Premium é saudável e nutritiva,
            com uma produção que evita o excesso do refinamento, mantendo
            assim seus nutrientes naturais.
            </p>
        </Col>
    </Row>
    </>
  )

const FARINHA = () =>(
    <>
        <Container fluid className="text-center">
            <Image className="mt-5" fluid width={250} src={FarinhaB}></Image>
            <h4 className="text-secondary mt-2">Farinha de Trigo Barão</h4>
            <Link to="/FAR">
                <Button size='lg' variant='primary'className='mt-1 mb-2'>Conheça o produto</Button>
            </Link>
        </Container>
    </>
)