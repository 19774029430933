import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaRegClock } from "react-icons/fa";
import { GiCookingGlove } from "react-icons/gi";
import { GoDotFill } from "react-icons/go";
import { IoRestaurantOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Rodape } from "../../Footer/Rodape";
import Barao2 from "../../Images/Receitas/BaraoDeDois.jpeg";
import { TopBar } from "../../Navbar/TopBar";

export const BaraoDeDois = () => {
	return (
		<>
            <TopBar />
			<INFO />
            <Rodape />
		</>
	)
}

const INFO = () => {
	return (
		<>
			<Container>
				<p className="display-1 mt-5">Barão de Dois</p>
				<Row>
					<Col xl={7}>
						<Image className="mb-1" width={620} fluid src={Barao2}></Image>
					</Col>
					<Col style={{ fontSize: '20px '}} xl={5}>
						<b className="text-red"> Enviada por:</b>
						<p style={{ borderBottom: 'solid', }} className="text-red"> All Nutri</p>
						<span className="as"></span>
						<p style={{ borderBottom: 'solid' }} className="text-red"> <FaRegClock /> 1+ Hora
							<span className=" ms-5"> <IoRestaurantOutline /> 8 Porções </span>
							<span className=" ms-5"> <GiCookingGlove /> Fácil </span> <br />
						</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className="text-red mt-5" style={{ fontSize: '40px' }}>Ingredientes</p> <br />
						<p> <GoDotFill /> 300 g de carne seca </p>
						<p> <GoDotFill /> 1 copo americano de feijão </p>
						<p> <GoDotFill /> 300 g de arroz </p>
						<p> <GoDotFill /> Óleo </p>
						<p> <GoDotFill /> 1 dente de alho </p>
						<p> <GoDotFill /> 1 cebola média </p>
						<p> <GoDotFill />Cheiro verde com salsa </p>
					</Col>
				</Row>
				<Row>
					<Col>
						<p
						className="text-red mt-5" style={{ fontSize: '40px' }}
						>
						Modo de Preparo
						</p>
						<p> Em uma panela de pressão, fervente o feijão
							por 5 minutos na pressão. Em outra panela,
							coloque o óleo, acrescente o alho e  deixe
							fritar bem e depois, coloque a cebola picadinha e
							deixe refogar até dourar.
						</p>
						<p> Junte a carne picadinha e lavada. Enquanto a carne
						vai fritando, vai colocando uma chícara de agua até a
						carne ficar macia. Junte o arroz e refogue por 5
						minutos. Acrescente água fervente e a pimenta de cheiro.
						</p>
					</Col>
				</Row>
				<Link to="/RECIPES">
                    <Button className="text-center" variant="outline-primary"> <AiOutlineArrowLeft />  VOLTAR </Button>
                </Link>
			</Container>
		</>
	)
}