/**
 * @author Frederico Ferracini Duarte
 * @since 2024-01-17 09:04:44
 */

import { RouteObject } from "react-router-dom"
import { App } from "../App"
import { Error } from "../ErrorPage"
import { FaleConosco } from "../FaleConosco/FaleConosco"
import { Arroz } from "../Products/LinhaArroz"
import { ArrozPampa } from "../Products/LinhaArroz/AP1"
import { ArrozBarao } from "../Products/LinhaArroz/BAR"
import { ArrozGRT } from "../Products/LinhaArroz/GRT"
import { ArrozMoranguinho } from "../Products/LinhaArroz/MRG"
import { ArrozTioPepe } from "../Products/LinhaArroz/TPE"
import { ArrozTioElias } from "../Products/LinhaArroz/eli"
import { Farinha } from "../Products/LinhaFarinha"
import { FarinhaBarao } from "../Products/LinhaFarinha/FAR"
import { Feijao } from "../Products/LinhaFeijao"
import { FeijaoBarao } from "../Products/LinhaFeijão/BC1"
import { FeijaoBPreto } from "../Products/LinhaFeijão/BP1"
import { FeijaoGarotinho } from "../Products/LinhaFeijão/GC1"
import { FeijaoGarotinhoPreto } from "../Products/LinhaFeijão/GP1"
import { FeijaoPampa } from "../Products/LinhaFeijão/PC1"
import { FeijaoPampaPreto } from "../Products/LinhaFeijão/PP1"
import { Vinagre } from "../Products/LinhaVinagre"
import { VinAlcool } from "../Products/LinhaVinagre/VIN-ALCOOL"
import { VinAlcoolColorido } from "../Products/LinhaVinagre/VIN-ALCOOL-COLORIDO"
import { VinComposto } from "../Products/LinhaVinagre/VIN-COMPOSTO"
import { VinMaca } from "../Products/LinhaVinagre/VIN-MACA"
import { VinTinto } from "../Products/LinhaVinagre/VIN-TINTO"
import { QuemSomos } from "../QuemSomos/QuemSomos"
import { ReceitasHome } from "../Receitas/ReceitasHome"
import { BaraoDeDois } from "../Receitas/SubReceitas/BaraoDeDois"
import { BolinhoDeFeijao } from "../Receitas/SubReceitas/BolinhoDeFeijao"
import { BolinhoDeFeijoada } from "../Receitas/SubReceitas/BolinhoDeFeijoada"
import { Trabalhe } from "../TrabalheConosco/Trabalhe"

export const Routes: Array<RouteObject> = [
	{
		path: "*",
		element: <Error />,
		// errorElement: <ErrorPage />,
	},
	{
		path: "/",
		element: <App />,
		// errorElement: <ErrorPage />,
	},
	{
		path: "/quemsomos",
		element: <QuemSomos />,
	},
	{
		path: "/Products/LinhaArroz",
		element: <Arroz />,
	},
	{
		path: "/Products/LinhaFeijao",
		element: <Feijao />,
	},
	{
		path: "/Products/LinhaFarinha",
		element: <Farinha />,
	},
	{
		path: "/Products/LinhaVinagre",
		element: <Vinagre />,
	},
	{
		path: "/FaleConosco",
		element: <FaleConosco />,
	},
	{
		path: "/TrabalheConosco",
		element: <Trabalhe />,
	},

	//PRODUTOS ARROZ ↓

	{
		path: "/BAR",
		element: <ArrozBarao />,
	},
	{
		path: "/GRT",
		element: <ArrozGRT />,
	},
	{
		path: "/AP1",
		element: <ArrozPampa />,
	},
	{
		path: "/TPE",
		element: <ArrozTioPepe />,
	},
	{
		path: "/ELI",
		element: <ArrozTioElias />,
	},
	{
		path: "/MRG",
		element: <ArrozMoranguinho />,
	},

	//PRODUTOS ARROZ FIM ↑

	//PRODUTOS FEIJÃO ↓

	{
		path: "/BC1",
		element: <FeijaoBarao />,
	},
	{
		path: "/BP1",
		element: <FeijaoBPreto />,
	},
	{
		path: "/GC1",
		element: <FeijaoGarotinho />,
	},
	{
		path: "/GP1",
		element: <FeijaoGarotinhoPreto />,
	},
	{
		path: "/PC1",
		element: <FeijaoPampa />,
	},
	{
		path: "/PP1",
		element: <FeijaoPampaPreto />,
	},

	//PRODUTOS FEIJÃO FIM ↑

	//PRODUTOS FARINHA ↓

	{
		path: "/FAR",
		element: <FarinhaBarao/>,
	},

	//PRODUTO FARINHA FIM ↑

	//PRODUTOS VINAGRE ↓

	{
		path: "/VINALCOOL",
		element: <VinAlcool/>,
	},
	{
		path: "/VINCOLORIDO",
		element: <VinAlcoolColorido/>,
	},
	{
		path: "/VINCOMPOSTO",
		element: <VinComposto/>,
	},
	{
		path: "/VINMACA",
		element: <VinMaca/>,
	},
	{
		path: "/VINTINTO",
		element: <VinTinto/>,
	},

	//PRODUTO VINAGRE FIM ↑

	//RECEITAS ↓

	{
		path: "/RECIPES",
		element: <ReceitasHome/>,
	},

	//RECEITAS FIM ↑


	//SUB-RECEITAS ↓

	{
		path: "/BaraoDois",
		element: <BaraoDeDois/>,
	},
	{
		path: "/BolinhoFeijao",
		element: <BolinhoDeFeijao/>,
	},
	{
		path: "/BolinhoFeijoada",
		element: <BolinhoDeFeijoada/>,
	},


	//SUB-RECEITAS FIM ↑

	//TRABALHE-CONOSCO ↓

	{
		path: "/faleconosco",
		element: <FaleConosco/>,
	},

	//TRABALHE-CONOSCO FIM ↑
]
