import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaRegClock } from "react-icons/fa";
import { GiCookingGlove } from "react-icons/gi";
import { GoDotFill } from "react-icons/go";
import { IoRestaurantOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Rodape } from "../../Footer/Rodape";
import BolinhoFeijoada from "../../Images/Receitas/BolinhoDeFeijoada.jpeg";
import { TopBar } from "../../Navbar/TopBar";

export const BolinhoDeFeijoada = () => {
	return (
		<>
            <TopBar />
			<INFO />
            <Rodape />
		</>
	)
}

const INFO = () => {
	return (
		<>
			<Container>
				<p className="display-1 mt-5">Bolinho de feijoada</p>
				<Row>
					<Col xl={7}>
						<Image className="mb-1" width={620} fluid src={BolinhoFeijoada}></Image>
					</Col>
					<Col style={{ fontSize: '20px '}} xl={5}>
						<b className="text-red"> Enviada por:</b>
						<p style={{ borderBottom: 'solid', }} className="text-red"> All Nutri</p>
						<span className="as"></span>
						<p style={{ borderBottom: 'solid' }} className="text-red"> <FaRegClock /> 1+ Hora
							<span className=" ms-5"> <IoRestaurantOutline /> 10 Porções </span>
							<span className=" ms-5"> <GiCookingGlove /> Médio </span> <br />
						</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className="text-red mt-5" style={{ fontSize: '40px' }}>Ingredientes</p> <br />
						<p> <GoDotFill /> 2 litros de água </p>
						<p> <GoDotFill /> 1/2 kg de feijão preto </p>
						<p> <GoDotFill /> 100g de carne seca dessalgada e cortada em cubinhos </p>
						<p> <GoDotFill /> 100g de lombo dessalgado e cortado em cubinhos </p>
						<p> <GoDotFill /> 100g de costelinha dessalgada (ou defumada)e </p>
						<p> <GoDotFill /> 1 linguiça calabresa cortada em cubos </p>
						<p> <GoDotFill /> 1 paio (linguiça de lombo de porco) cortado em cubos </p>
                        <p> <GoDotFill /> 3 folhas de louro </p>
						<p> <GoDotFill /> 2 colheres (sopa) de azeite </p>
						<p> <GoDotFill /> 3 dentes de alho </p>
                        <p> <GoDotFill /> 300g de farinha de mandioca amarela (fina, que não seja torrada) </p>
						<p> <GoDotFill /> 1 colher (sopa) de polvilho azedo </p>
                        <p> <GoDotFill /> 250g de bacon (para refogar a couve) cortado em cubinhos </p>
						<p> <GoDotFill /> 2 dentes de alho </p>
                        <p> <GoDotFill /> 2 maços de couve cortada em tiras </p>
						<p> <GoDotFill /> Farinha própria para empanar </p>
					</Col>
				</Row>
				<Row>
					<Col>
						<p
						className="text-red mt-5" style={{ fontSize: '40px' }}
						>
						Modo de Preparo
						</p>
						<p> 1 – Numa panela de pressão coloque 2 litros de água,
                        o feijão preto, a carne seca dessalgada e cortada em
                        cubinhos, o lombo dessalgado e cortado em cubinhos, a
                        costelinha dessalgada (ou defumada), a linguiça calabresa
                        cortada em cubos, o paio cortado em cubos e as folhas de
                        louro. Tampe a panela e coloque em fogo médio por +/-
                        1 hora, contadas após pegar pressão. Retire totalmente a
                        pressão e abra a panela.
						</p>
						<p> 2 – Transfira o feijão, o caldo e as carnes para um
                        liquidificador (faça isso por partes) e bata bem.
						</p>
                        <p> 3 – Numa panela grande aqueça o azeite, doure os
                        dentes de alho, refogue o feijão batido e acerte o sal.
						</p>
                        <p> 4 – AOS POUCOS vá acrescentando a farinha de
                        mandioca amarela (fina, que não seja torrada) sem parar
                        de mexer. Mexa vigorosamente até o tutu soltar do fundo
                        da panela. Coloque esse tutu pronto em um recipiente
                        para esfriar.
						</p>
                        <p> 5 – Depois do tutu frio, incorpore o polvilho azedo
                        e trabalhe bem a massa para que fique homogênea
                        (bem lisinha). Reserve.
						</p>
                        <p> 6 – Enquanto isso, numa panela, doure o bacon
                        cortado em cubinhos e os dentes de alho. Acrescente a
                        couve cortada em tiras, o sal (a gosto) e desligue o
                        fogo rapidamente, só para dar um “susto” na couve.
						</p>
                        <p> 7 - Pegue uma porção da massa e abra um disco na palma
                        de uma mão. Adicione uma colher da couve refogada no
                        centro do bolinho e feche-o bem. Passe na farinha
                        para empanar e frite os bolinhos em óleo bem quente.
                        Sirva com torresmo, gomos de laranja e uma batidinha de
                        limão para beber.
						</p>
                        <p> Bom, agora vou indo. <br /> Beijo e até mais!</p> <br />
					</Col>
				</Row>
                <Link to="/RECIPES">
                    <Button className="text-center" variant="outline-primary"> <AiOutlineArrowLeft />  VOLTAR </Button>
                </Link>
			</Container>
		</>
	)
}