/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-01-26 09:43:50
 */

import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Rodape } from '../Footer/Rodape';
import Abarao from "../Images/LinhaProdutos/Arroz/ArrozBarao.png";
import Agrt from "../Images/LinhaProdutos/Arroz/ArrozGarotinho.png";
import Amrg from "../Images/LinhaProdutos/Arroz/ArrozMoranguinho.png";
import Apampa from "../Images/LinhaProdutos/Arroz/ArrozPampa.png";
import Aeli from "../Images/LinhaProdutos/Arroz/ArrozTioElias.png";
import Atpe from "../Images/LinhaProdutos/Arroz/ArrozTioPepe.png";
import LinhaArroz from "../Images/LinhaProdutos/LinhaArroz.png";
import { TopBar } from '../Navbar/TopBar';

export function Arroz() {
  return (
    <>
      <TopBar/>
      <QW/>
      <CARDS/>
      <Rodape/>
    </>
  );
}

const QW = () =>(
  <>
    <Row className='bg-secondary'>
      <Col sm={5}>
        <Image fluid width={470} src={LinhaArroz}></Image>
      </Col>
      <Col sm={6} className='text-white text-center mt-3'>
        <h1>Linha Arroz</h1>
        <p>Com uma rigorosa seleção e polimento especial feito com água,
          a Barão Alimentos oferece uma linha de arroz especial de alta
          qualidade, beneficiando grão a grão cereais de primeira.</p>
      </Col>
      <Col>
      </Col>
    </Row>
  </>
)

const CARDS = () =>(
  <>
    <Container className='mt-5'>
      <Row>
        <Col className="text-center mb-5" xl={6}>
          <Image fluid width={200} src={Abarao}></Image> <br />
          <h4 className="text-secondary">Arroz Barão</h4>
          <Link to="/BAR">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={200} src={Agrt}></Image> <br />
          <h4 className='text-secondary'>Arroz Garotinho</h4>
          <Link to="/GRT">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={210} src={Apampa}></Image> <br />
          <h4 className='text-secondary'>Arroz Pampa</h4>
          <Link to="/AP1">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={210} src={Atpe}></Image> <br />
          <h4 className='text-secondary'>Arroz Tio Pepe</h4>
          <Link to="/TPE">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={430} src={Aeli}></Image> <br />
          <h4 className='text-secondary'>Arroz Tio Elias</h4>
          <Link to="/ELI">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={450} src={Amrg}></Image> <br />
          <h4 className='text-secondary'>Arroz Moranguinho</h4>
          <Link to="/MRG">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  </>
)
