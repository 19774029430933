/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-02-20 16:24:30
 */

import { Button, Col, Container, Image, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import Far from "../../Images/LinhaProdutos/Farinha/FarinhaBarao.png";
import { TopBar } from "../../Navbar/TopBar";

export function FarinhaBarao() {
    return (
    <>
    <TopBar/>
    <Container>
    <Farinha/>
    </Container>

    </>
    );
  }

  const Farinha =() =>(
    <>
      <Row>
        <Col xl={3}>
            <Image className="mt-5" src={Far} fluid width={210}></Image>
        </Col>
        <Col xl={9}>
            <Tabs variant="pills"
                fill
                defaultActiveKey="info"
                id="uncontrolled-tab-example"
                className="mt-5">
            <Tab className="border" eventKey="info" title="Informações">
                <h1 className="text-center mt-3">Farinha de Trigo Barão</h1>
                <p className="text-center">
                Nossa farinha de trigo é um produto diferenciado e com qualidade superior.
                Produzida a partir da moagem especial de grãos  criteriosamente
                selecionados a Farinha de Trigo Barão Premium é perfeita para
                qualquer receita, seja para pratos triviais ou elaborados. Aqui
                você encontra muito sabor e saúde graças ao enriquecimento com
                ferro e ácido fólico.
                </p>
            <Table className="text-center mt-4">
                <thead>
                    <tr>
                        <th> Classe </th>
                        <th> Tipo </th>
                        <th> Grupo </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Refinado</td>
                        <td>1</td>
                        <td>Premium</td>
                    </tr>
                </tbody>
            </Table>
            <Link to="/Products/LinhaFarinha">
                <Button
                    className="bi-arrow-return-left mb-1" variant="outline-danger"> Voltar
                </Button>
            </Link>
            </Tab>
            <Tab className="border" eventKey="tabela" title="Tabela Nutricional">
            <Table striped>
                <thead>
                    <tr>
                        <th>Porção de 50g / (medida caseira)</th>
                        <th>Quantidade por porção</th>
                        <th>%VD*</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Valor Calórico</td>
                        <td>180 kca</td>
                        <td>9%</td>
                    </tr>
                    <tr>
                        <td>Carboidrato</td>
                        <td>38g</td>
                        <td>13%</td>
                    </tr>
                    <tr>
                        <td>Proteina</td>
                        <td>5g</td>
                        <td>7%</td>
                    </tr>
                    <tr>
                        <td>Gorduras Totais</td>
                        <td>1g</td>
                        <td>2%</td>
                    </tr>
                    <tr>
                        <td>Gordura Saturada</td>
                        <td>0g</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Colesterol</td>
                        <td>0 kca</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Fibra Alimentar</td>
                        <td>1g</td>
                        <td>4%</td>
                    </tr>
                    <tr>
                        <td>Cálcio</td>
                        <td>9g</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>Ferro</td>
                        <td>2,1g</td>
                        <td>15%</td>
                    </tr>
                    <tr>
                        <td>Sódio</td>
                        <td>0g</td>
                        <td>0%</td>
                    </tr>
                </tbody>
            </Table>
            <Link to="/Products/LinhaFarinha">
                <Button
                    className="bi-arrow-return-left mb-1" variant="outline-danger"> Voltar
                </Button>
            </Link>
            </Tab>
            </Tabs>
        </Col>
        </Row>
    </>
  )