/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-01-29 17:18:30
 */

import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Rodape } from '../Footer/Rodape';
import Fbar from "../Images/LinhaProdutos/Feijao/FeijaoBarao.png";
import FbarP from '../Images/LinhaProdutos/Feijao/FeijaoBaraoP.png';
import Fgrt from "../Images/LinhaProdutos/Feijao/FeijaoGarotinho.png";
import FgrtP from "../Images/LinhaProdutos/Feijao/FeijaoGarotinhoP.png";
import Fpmp from "../Images/LinhaProdutos/Feijao/FeijaoPampa.png";
import FpmpP from "../Images/LinhaProdutos/Feijao/FeijaoPampaP.png";
import LinhaFeijao from "../Images/LinhaProdutos/LinhaFeijao.png";
import { TopBar } from '../Navbar/TopBar';

export function Feijao() {
  return (
    <>
      <TopBar/>
      <QW/>
      <CARDS/>
      <Rodape/>
    </>
  );
}

const QW = () =>(
  <>
    <Row className='bg-secondary'>
        <Col sm={5}>
            <Image className='mt-3' fluid width={490} src={LinhaFeijao}></Image>
        </Col>
        <Col sm={6} className='text-white text-center mt-4'>
            <h1>Linha Feijão</h1>
            <p>Separados eletronicamente por tamanho, massa e cor, os feijões da
            Barão Alimentos se destacam em sua produção natural e sua
            consistência homogenia na panela.</p>
        </Col>
        <Col>
        </Col>
    </Row>
  </>
)

const CARDS = () =>(
  <>
    <Container className='mt-5'>
      <Row>
        <Col className="text-center mb-5" xl={6}>
          <Image fluid width={250} src={Fbar}></Image>
          <h4 className="text-secondary">Feijão Barão</h4>
          <Link to="/BC1">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={250} src={FbarP}></Image>
          <h4 className='text-secondary'>Feijão Barão Preto</h4>
          <Link to="/BP1">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={250} src={Fgrt}></Image>
          <h4 className='text-secondary'>Feijão Garotinho</h4>
          <Link to="/GC1">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={250} src={FgrtP}></Image>
          <h4 className='text-secondary'>Feijão Garotinho Preto</h4>
          <Link to="/GP1">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={250} src={Fpmp}></Image>
          <h4 className='text-secondary'>Feijão Pampa</h4>
          <Link to="/PC1">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
        <Col className='text-center mb-5' xl={6}>
          <Image fluid width={250} src={FpmpP}></Image>
          <h4 className='text-secondary'>Feijão Pampa Preto</h4>
          <Link to="/PP1">
            <Button size='lg' variant='primary'className='mt-1'>Conheça o produto</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  </>
)
