/**
 * @author Frederico Ferracini Duarte
 * @since 2024-01-17 09:10:44
 */

import { Card, CardGroup, Container, Image, Row, Tab, Tabs } from "react-bootstrap";
import { Rodape } from "../Footer/Rodape";
import i1 from "../Images/ParqueIndustrial/01.jpg";
import i2 from "../Images/ParqueIndustrial/02.jpg";
import i3 from "../Images/ParqueIndustrial/03.jpg";
import empresa from "../Images/bg-emp.jpg";
import defamilia from "../Images/danossafamilia.png";
import parqueindustrial from "../Images/parqueindustrial.png";
import somos from "../Images/somosmaisbarao.png";
import soseforbarao from "../Images/soseforbarao.png";
import { TopBar } from "../Navbar/TopBar";
import "../QuemSomos/tabs.scss";

export const QuemSomos = (): JSX.Element => {
	return (
		<>
			<TopBar />
			<Card className="bg-primary">
				<Card.Img  src={empresa}/>
				<Card.ImgOverlay>
					<Card.Title className="text-white text-center">
						<h3 className="display-1 mt-lg-5">Quem Somos</h3>
					</Card.Title>
				</Card.ImgOverlay>
			</Card>
			<Container>
				<TABS/>
			</Container>
			<Rodape/>
		</>
  );
}

const TABS = () =>{
	return(
		<>
			<Row className="justify-content-center">
				<Tabs justify variant="pills" defaultActiveKey="tab-1" className="a mb-1 p-0">
					<Tab eventKey="tab-1" title="ㅤHistóriaㅤ">
						<História/>
					</Tab>
					<Tab eventKey="tab-2" title="Politica de Qualidade">
						<Politica/>
					</Tab>
					<Tab eventKey="tab-3" title="Parque Industrial">
						<Parque/>
					</Tab>
				</Tabs>
			</Row>
		</>
	)
}

const História =() => {
	return(
		<>
			<h1 className="text-center mt-4">Uma empresa mais saudável começa com uma vida mais</h1> <br />
			<p>Saúde é o que norteia o padrão de qualidade dos processos de seleção Barão Alimentos: da lavoura à mesa
			do consumidor. Atuando há 28 anos no mercado goiano do segmento	alimentício, e hoje presente  em diversos
			estados brasileiros, a empresa vem se destacando pela qualidade e excelência na seleção dos grãos
			que comercializa, com prioridade na qualidade total: desde a seleção ao empacotamento de uma ampla linha de
			produtos. E para atender à grande demanda, sua indústria foi transferida para o Polo Empresarial de
			Aparecida de Goiânia, aumentando assim sua capacidade de produção e de escoamento.
			</p> <br />

			<p>Para garantir uma alimentação saudável e saborosa à mesa dos brasileiros, a prioridade da empresa
			é a qualidade, seja na forma como valoriza as pessoas, na preocupação com o bem estar-social ou ainda na
			responsabilidade com desenvolvimento sustentável. Tanta atenção que fez com que a Barão Alimentos se
			posicionasse entre as 10 maiores marcas do setor cerealista brasileiro,  voltadas para a produção de feijão
			e arroz. A logística no Estado de Goiás, cento do País, é feita de uma maneira objetiva, facilitando assim
			o escoamento da produção nos demais estados brasileiros, e a pontualidade nos prazos de entrega cumprida
			sempre com exatidão.
			</p> <br />

			<p>O esforço diário para atender cada vez melhor as famílias brasileiras é a receita que faz do
			relacionamento com os parceiros uma oportunidade de expansão e abertura de novos mercados . Agora falta
			você entrar para esse time e deixar a Barão Alimentos lhe surpreender.
			</p>

			<Image src={soseforbarao} fluid></Image>

			<p>Para ter o privilégio de fazer parte da sua vida, o Barão conquistou um diferencial que vai além da
			qualidade dos grãos. O nosso compromisso é com a experiência de uma alimentação completa e saudável para
			você e toda a sua família.
			</p> <br />

			<p>Com experiência e excelência vem transformando dia-a-dia o o trivial Arroz com Feijão em uma refeição
			saborosa, hoje podemos colher a satisfação de ser referência no segmento, e isso nos estimula a continuar
			buscando a excelência e a levando para a sua mesa.
			</p> <br />

			<p>Tenha em sua cozinha e em sua vida produtos que agreguem qualidade, sabor e satisfação. Escolha o melhor
			para sua família.</p> <br />

			<p>O melhor? <b>SÓ SE FOR BARÃO.</b> </p> <br />

			<h2 className="text-center text-white border border-primary bg-primary rounded">História através do tempo
			</h2>

			<h4> <b>Missão</b></h4>
			<p>Ser uma empresa mais saudável à mesa. Respeitar as pessoas e, acima de tudo , a saúde de seus
			consumidores. Para isso é preciso manter o compromisso com o critério de qualidade, a prática constante
			do aprimoramento pessoal e profissional dos colaboradores, o desenvolvimento sustentável da sociedade e a
			satisfação dos consumidores.
			</p> <br />

			<h4> <b>Visão</b></h4>
			<p>Ser referência nacional em alimentos saudáveis.</p>

			<h4> <b>Valores</b></h4>
			<p>• Saudável em tudo.</p>
			<p>• Compromisso com a qualidade.</p>
			<p>• Seleção em todo o processo de produção.</p>
			<p>• Ética e transparência na gestão de pessoas e dos processos.</p>
			<p>• Aprimoramento pessoal e profissional dos colaboradores.</p>
			<p>• Responsabilidade social e ambiental.</p>
			<p>• Respeito às pessoas.</p>
			<p>• Satisfação dos clientes.</p>
		</>
	)
}

const Politica =() => {
	return(
		<>
			<h1 className="text-center mt-4">Política de Qualidade Barão Alimentos</h1> <br />
			<p>O compromisso da Barão Alimentos é o de produzir alimentos seguros que obedeçam a legislação em vigor,
			que proporcione a satisfação ao cliente, que elimine desperdícios em sua cadeia produtiva e que zele pelo
			ambiente, eliminando possíveis impactos ambientais.
			</p> <br />
			<p>E, reforçando seus objetivos e compromissos, a Barão Alimentos tem em sua essência empresarial diretrizes
			que corroboram para garantir a superioridade dos produtos e oferecer qualidade e confiança para os seus
			consumidores
			</p>

			<Image src={defamilia} width={640} fluid></Image>

			<p>Além da preocupação com os grãos, a empresa também investe em infraestrutura para garantir a satisfação
			dos profissionais da Barão Alimentos, assim como a dos clientes.</p> <br />

			<Image src={somos} fluid></Image>
		</>
	)
}

const Parque =() => {
	return(
		<>
			<h1 className="text-center mt-4">Escolher é selecionar</h1> <br />
			<b>A qualidade está presente do início ao fim do processo do empacotamento de grãos.</b> <br />

			<p>O padrão de qualidade Barão Alimentos está presente em toda sua metodologia de trabalho, da estrutura
			tecnológica ao produto final. Isso porque para a empresa, qualidade é seleção. Oferecer produtos de
			de primeira linha para as famílias brasileiras é fundamental para que todos os clientes e parceiros saiam
			satisfeitos.</p>

			<p>Técnicos especializados e uma equipe capacitada são responsáveis por estudar e comprar os cereais
			provenientes das melhores plantações homogêneas do País. Esse método preserva os nutrientes e garante a
			procedência da safra, sendo ela a única do Brasil a fazer esse tipo de compra.
			</p> <br />

			<Image src={parqueindustrial} fluid></Image>

			<p>Na linha de produção, há, ainda, a separação manual dos grãos segundo padrões de tamanho e cor. De grão
			em grão, chega-se ao Teste de Cozimento que antecede o ensacamento do produto. Tudo pensando exclusivamente
			na saúde e satisfação dos consumidores.
			</p> <br/>

			<h1 className="text-center">Tecnologia</h1>
			<b>A Barão Alimentos faz parte da nova geração de empresas.</b> <br />

			<p>Para a Barão Alimentos, o crescimento econômico está diretamente ligado à sustentabilidade. E agir com
			essa consciência é atender às necessidades presentes, sem comprometer possibilidades futuras. Com modernas
			nstalações e equipamento de última geração, a indústria multiplica e otimiza o já consolidado padrão de
			qualidade dos produtos.
			</p>

			<p>Agora localizada no Distrito Agroindustrial de Aparecida de Goiânia, os investimentos na nova sede
			possibilitaram o aumento da produção e o suprimento de grande parte da necessidade interna de grãos do País.
			Tudo pensado para a saúde e satisfação dos consumidores.
			</p>

			<CardGroup>
      			<Card>
        			<Card.Img variant="top" src={i1} />
      			</Card>
      			<Card>
        			<Card.Img variant="top" src={i2} />
      			</Card>
      			<Card>
        			<Card.Img variant="top" src={i3} />
      			</Card>
    		</CardGroup>
		</>
	)

}