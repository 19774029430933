/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-02-20 13:54:56
 */



import { Button, Col, Container, Image, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import VinagreAlcool from "../../Images/LinhaProdutos/Vinagre/VinagreAlcool.png";
import { TopBar } from "../../Navbar/TopBar";

export function VinAlcool() {
    return (
    <>
    <TopBar/>
    <Container>
    <TABS/>
    </Container>

    </>
    );
  }

  const TABS =() =>(
    <>
        <Row>
        <Col xl={3}>
            <Image className="mt-5 ms-3 text-center" fluid width={210} src={VinagreAlcool}></Image>
        </Col>
        <Col xl={9}>
            <Tabs variant="pills"
                fill
                defaultActiveKey="info"
                id="uncontrolled-tab-example"
                className="mt-5">
            <Tab className="border" eventKey="info" title="Informações">
                <h1 className="text-center mt-3">Vinagre de Álcool</h1>
                <p className="text-center">
                A Barão Alimentos apresenta a Linha de Vinagres Saborizados.
                Eles vieram para deixar as suas receitas ainda mais
                inesquecíveis . São seis sabores ricos em qualidade, sem glúten
                e especialmente elaborados para realçar seus pratos e garantir
                elogios à mesa.
                </p>
            <Table className="text-center mt-4">
                <thead>
                    <tr>
                        <th> Classe </th>
                        <th> Tipo </th>
                        <th> Grupo </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Mesa</td>
                        <td>1</td>
                        <td>Álcool</td>
                    </tr>
                </tbody>
            </Table>
            <Link to="/Products/LinhaVinagre">
                <Button
                    className="bi-arrow-return-left mb-1" variant="outline-danger"> Voltar
                </Button>
            </Link>
            </Tab>
            <Tab className="border" eventKey="tabela" title="Tabela Nutricional">
            <Table striped>
                <thead>
                    <tr>
                        <th>Porção de 100g / (medida caseira)</th>
                        <th>Quantidade por porção</th>
                        <th>%VD*</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Valor Calorico</td>
                        <td>18 kca</td>
                        <td>18%</td>
                    </tr>
                    <tr>
                        <td>Carboidrato</td>
                        <td>0,04g</td>
                        <td>0,04%</td>
                    </tr>
                    <tr>
                        <td>Proteina</td>
                        <td>0g</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Gorduras Totais</td>
                        <td>0g</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Gordura Saturada</td>
                        <td>0g</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Colesterol</td>
                        <td>0 kca</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Fibra Alimentar</td>
                        <td>8g</td>
                        <td>27%</td>
                    </tr>
                    <tr>
                        <td>Cálcio</td>
                        <td>0g</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Ferro</td>
                        <td>0,03g</td>
                        <td>0,03%</td>
                    </tr>
                    <tr>
                        <td>Sódio</td>
                        <td>2g</td>
                        <td>2%</td>
                    </tr>
                </tbody>
            </Table>
            <Link to="/Products/LinhaVinagre">
                <Button
                    className="bi-arrow-return-left mb-2" variant="outline-danger"> Voltar
                </Button>
            </Link>
            </Tab>
            </Tabs>
        </Col>
        </Row>
    </>
  )