/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-01-26 09:43:50
 */

import { Carousel } from "react-bootstrap";
import CARRO2 from "../Images/CARRO2.jpg";
import CARRO3 from "../Images/CARRO3.jpg";


export default function Carrossel() {
    return (
      <div>
      <Carousel>
        <Carousel.Item interval={2500}>
          <img
            className="d-block w-100"
            src= {CARRO2}
            alt="Image One"
          />
        </Carousel.Item>
        <Carousel.Item interval={2500}>
          <img
            className="d-block w-100"
            src={CARRO3}
            alt="Image Two"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}