/**
 * @author Frederico Ferracini Duarte
 * @since 2024-01-16 10:32:41
 */

import { Container } from "react-bootstrap";
import Carrossel from "./Carrossel/Carrossel";
import { Rodape } from "./Footer/Rodape";
import { Produtos } from "./Home/Produtos";
import { Rede } from "./Home/SocialMedia";
import { TopBar } from "./Navbar/TopBar";

export const App = () => {
	return (
		<>
			<TopBar />
			<Carrossel/>
			<Container>
				{/*<h3 className="text-center">Nosso site está em manutenção
				<p>Novidades chegando em breve!</p></h3>*/}
			<Produtos/>
			</Container>
			<Rede/>
			<Rodape/>
		</>
	)
}
