import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaRegClock } from "react-icons/fa";
import { GiCookingGlove } from "react-icons/gi";
import { GoDotFill } from "react-icons/go";
import { IoRestaurantOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Rodape } from "../../Footer/Rodape";
import BolinhoFeijao from "../../Images/Receitas/BolinhoDeFeijão.jpeg";
import { TopBar } from "../../Navbar/TopBar";

export const BolinhoDeFeijao = () => {
	return (
		<>
            <TopBar />
			<INFO />
            <Rodape />
		</>
	)
}

const INFO = () => {
	return (
		<>
			<Container>
				<p className="display-1 mt-5">Bolinho de feijão</p>
				<Row>
					<Col xl={7}>
						<Image className="mb-1" width={620} fluid src={BolinhoFeijao}></Image>
					</Col>
					<Col style={{ fontSize: '20px '}} xl={5}>
						<b className="text-red"> Enviada por:</b>
						<p style={{ borderBottom: 'solid', }} className="text-red"> All Nutri</p>
						<span className="as"></span>
						<p style={{ borderBottom: 'solid' }} className="text-red"> <FaRegClock /> 1+ Hora
							<span className=" ms-5"> <IoRestaurantOutline /> 10 Porções </span>
							<span className=" ms-5"> <GiCookingGlove /> Médio </span> <br />
						</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className="text-red mt-5" style={{ fontSize: '40px' }}>Ingredientes</p> <br />
						<p> <GoDotFill /> 500g de feijão </p>
						<p> <GoDotFill /> 1 cenoura média ralada </p>
						<p> <GoDotFill /> 1 xicara (café) de azeitona picadinha </p>
						<p> <GoDotFill /> Pimenta calabresa, cheiro verde, sal </p>
						<p> <GoDotFill /> 1 tablete de caldo de carne </p>
						<p> <GoDotFill /> Tomate, milho </p>
						<p> <GoDotFill /> 100g de queijo picadinho </p>
                        <p> <GoDotFill /> 4 ovos </p>
						<p> <GoDotFill /> 200g de farinha de pão </p>
						<p> <GoDotFill /> 1 litro de óleo </p>
                        <p> <GoDotFill /> Rúcula picadinha </p>
						<p> <GoDotFill /> Agrião </p>
					</Col>
				</Row>
				<Row>
					<Col>
						<p
						className="text-red mt-5" style={{ fontSize: '40px' }}
						>
						Modo de Preparo
						</p>
						<p> Cozinhe o feijão por cerca de 30 minutos. Escorra e
                        passe a massa em um espremedor de batata. Junte a cenoura
                        ralada, a rúcula, o agrião, o cheiro verde, a pimenta
                        calabresa, o caldo de carne e sal a gosto Misture até
                        formar uma massa consistente.
						</p>
						<p> Em seguida unte as mãos na farinha de pão e faça um
                        bolinho em formato de quibe e que seja oco para que você
                        possa acrescentar o recheio (tomate, queijo e azeitona
                        picadinha) e feche o bolinho. Passe-os em ovos batidos
                        e depois na farinha de pão. Frite em óleo bem quente.
                        Decore seu prato a gosto , reúne os amigos e saboreie.
						</p>
					</Col>
				</Row>
                <Link to="/RECIPES">
                    <Button className="text-center" variant="outline-primary"> <AiOutlineArrowLeft />  VOLTAR </Button>
                </Link>
			</Container>
		</>
	)
}