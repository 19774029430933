import { Container, Image } from "react-bootstrap"
import { Rodape } from "./Footer/Rodape"
import erro from "./Images/Error/404.png"
import { TopBar } from "./Navbar/TopBar"

export const Error = () => {
	return (
		<>
			<TopBar />
			<Container className="text-center">
				<Image width={670} fluid src={erro}></Image>
			</Container>
			<Rodape/>
		</>
	)
}