/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-02-20 13:05:41
 */



import { Button, Col, Container, Image, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import grt from "../../Images/LinhaProdutos/Arroz/ArrozGarotinho.png";
import { TopBar } from "../../Navbar/TopBar";

export function ArrozGRT() {
    return (
    <>
    <TopBar/>
    <Container>
    <TABS/>
    </Container>

    </>
    );
  }

  const TABS =() =>(
    <>
        <Row>
        <Col xl={3}>
            <Image className="mt-5 ms-3 text-center" fluid width={210} src={grt}></Image>
        </Col>
        <Col xl={9}>
            <Tabs variant="pills"
                fill
                defaultActiveKey="info"
                id="uncontrolled-tab-example"
                className="mt-5">
            <Tab eventKey="info" title="Informações">
                <h1 className="text-center mt-3">Arroz Garotinho</h1>
                <p className="text-center">
                Para oferecer o melhor produto, buscamos a qualidade desde o plantio.
                Nossas equipes especializadas visitam as lavouras e analisam as
                condições de cada safra primando pela qualidade máxima, uma marca da All Nutri.
                </p>
            <Table className="text-center mt-4">
                <thead>
                    <tr>
                        <th> Classe </th>
                        <th> Tipo </th>
                        <th> Grupo </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Longo Fino</td>
                        <td>1</td>
                        <td>Polido</td>
                    </tr>
                </tbody>
            </Table>
            <Link to="/Products/LinhaArroz">
                <Button
                    className="bi-arrow-return-left" variant="outline-danger"> Voltar
                </Button>
            </Link>
            </Tab>
            <Tab eventKey="tabela" title="Tabela Nutricional">
            <Table striped>
                <thead>
                    <tr>
                        <th>Porção de 50g / (medida caseira)</th>
                        <th>Quantidade por porção</th>
                        <th>%VD*</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Valor Calorico</td>
                        <td>180 kca</td>
                        <td>7%</td>
                    </tr>
                    <tr>
                        <td>Carboidrato</td>
                        <td>70g</td>
                        <td>11%</td>
                    </tr>
                    <tr>
                        <td>Proteina</td>
                        <td>3g</td>
                        <td>6%</td>
                    </tr>
                    <tr>
                        <td>Gorduras Totais</td>
                        <td>0g</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Gordura Saturada</td>
                        <td>0g</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Colesterol</td>
                        <td>0 kca</td>
                        <td>0%</td>
                    </tr>
                    <tr>
                        <td>Fibra Alimentar</td>
                        <td>1g</td>
                        <td>3%</td>
                    </tr>
                    <tr>
                        <td>Cálcio</td>
                        <td>12g</td>
                        <td>1%</td>
                    </tr>
                    <tr>
                        <td>Ferro</td>
                        <td>1g</td>
                        <td>7%</td>
                    </tr>
                    <tr>
                        <td>Sódio</td>
                        <td>0g</td>
                        <td>0%</td>
                    </tr>
                </tbody>
            </Table>
            <Link to="/Products/LinhaArroz">
                <Button
                    className="bi-arrow-return-left" variant="outline-danger"> Voltar
                </Button>
            </Link>
            </Tab>
            </Tabs>
        </Col>
        </Row>
    </>
  )