/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-01-17 13:31:54
 */

import { Image } from "react-bootstrap"
import "../Footer/footer.scss"
import barao from "../Images/Logo.png"
import selo from "../Images/SELO.png"

export const Rodape = () => {
  return(
    <>
        <div className="Footer">
             <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-5 col-12 ft-1">
                        <h3><span> {/*INSIRA ALGO */} </span></h3>
                        <Image width={150} src={barao}></Image>
                        <Image width={135} src={selo}></Image>
                        <div className="footer-icons mb-3">
                            <a href="https://www.facebook.com/baraoalimentosoficial" className="bi-facebook"></a>
                            <a href="https://twitter.com/barao_alimentos" className="bi-twitter-x"></a>
                            <a href="https://www.instagram.com/baraoalimentosoficial/" className="bi-instagram"></a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-12 ft-2">
                        <h5>Guia Rapido</h5>
                        <ul>
                            <li className="nav-item">
                                <a className="" href="/quemsomos">Quem somos</a>
                            </li>
                            <li className="nav-item">
                                <a className="" href="/Products/LinhaArroz">Produtos</a>
                            </li>
                            <li className="nav-item">
                                <a className="" href="/RECIPES">Receitas</a>
                            </li>
                            <li className="nav-item">
                                <a className="" href="/FaleConosco">Fale Conosco</a>
                            </li>
                           {/* <li className="nav-item">
                                    <a className="" href="/">Trabalhe Conosco</a>
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12 ft-3">
                            <h5>Guia Rapido</h5>
                            <p><i className="bi-telephone-fill"></i>
                                <a href="tel:(62) 32955000">+55 62 3295-5000 </a></p>
                            <p><i className="bi-envelope-fill"></i> rh@arrozefeijaobarao.com.br</p>
                            <p><i className="bi-geo-alt-fill"></i>
                                <a href="https://maps.app.goo.gl/BtHnW7PNw2khcxNx8"> Google Mapa</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Last-footer'>
                <p>All Nutri Alimentos &copy;</p>
            </div>
    </>
    )
  }
