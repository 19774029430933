import { Button, Container, FloatingLabel, Form } from "react-bootstrap";
import { Rodape } from "../Footer/Rodape";
import { TopBar } from "../Navbar/TopBar";

export function Trabalhe() {
    return(
        <>
            <TopBar />
            <Formulario />
            <Rodape />
        </>
    )
}

function Formulario() {
    return(
        <>
            <Container className="col-10">
                <FloatingLabel
                controlId="floatingInput"
                label="E-mail"
                className="mb-3 mt-4"
                >
                    <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    />
                </FloatingLabel>

                <FloatingLabel
                controlId="floatingName"
                label="Nome"
                className="mb-3"
                >
                    <Form.Control type="text" placeholder="Nome" />
                </FloatingLabel>

                <FloatingLabel
                controlId="floatingTelephone"
                label="Número do Telefone (DDD) + Número"
                className="mb-3">
                    <Form.Control type="tel" placeholder="DDD + Número" />
                </FloatingLabel>

                <FloatingLabel
                className=" mb-3"
                controlId="floatingMessage"
                label="Mensagem">
                    <Form.Control
                    style={{height:"150px"}}
                    as="textarea"
                    placeholder="Recado"/>
                </FloatingLabel>

                <Form className="mb-2">
                    <Button>Enviar</Button>
                </Form>
            </Container>
        </>
    )
}